import './Footer.css';

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
        <div className="footer">
            <div className="top">
                <div className="left">
                    <h3>Tarif-VTC.fr</h3>
                    <p> Bienvenue chez Tarif-VTC, votre partenaire de confiance pour un transport de qualité supérieure. Profitez de notre large choix de véhicules haut de gamme et réservez en ligne dès maintenant.</p>
                </div>
                <div className="right">
                    <div className="SiteMap">
                        <h4>LIEN DU SITE</h4>
                        <ul>
                            <li><Link to='/'>Accueil</Link></li>
                            <li><Link to='/a-propos'>A propos</Link></li>
                            <li><Link to='/taxi-6-places'>Taxi 6 places</Link></li>
                            <li><Link to='/taxi-longue-distance'>Taxi longue distance</Link></li>
                            <li><Link to='/vtc-roissy-charles-de-gaulle'>vtc roissy charles de gaulle</Link></li>
                        </ul>
                    </div>
                    <div className="siteInfos">
                        <h4>NOUS CONTACTER</h4>
                        <p>104 Avenue Anatole France - 94400 Vitry sur Seine FRANCE</p>
                        <p>Téléphone : 06.50.24.09.98</p>
                        <p>Email : mdprive1@gmail.com</p>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <p>© Copyright Tarif-VTC.fr All Rights Reserved | <Link to='/mentions-legales'>Mentions Légales</Link> | <Link to='politique-de-confidentialite'>Politiques de confidentialités</Link></p>
            </div>
        </div>
    </>
  );
};
export default Footer;